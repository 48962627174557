import "../../css/components/_marquee.scss";
/**
 * Marquee options
 * @typedef {Object} Marquee#MarqueeOptions
 * @property {number} speed
 * @property {('left'|'right')} direction
 * @property {boolean} pauseOnHover
 * @property {boolean} allowDrag
 */

/**
 * Default marquee options
 * @type Marquee#MarqueeOptions
 * @defaultvalue
 * @private
 */
const _defaults = {
  speed: 1,
  direction: "left",
  pauseOnHover: false,
  allowDrag: false,
};
/**
 * @class
 * @example
 * const marquee = new Marquee(document.querySelector(".marquee"), {});
 * // or
 * const marquee = new Marquee(document.querySelector(".marquee")), {});
 */
class Marquee {
  #rafId;
  #observer;
  #resizeObserver;
  #elIntersectionObserver;
  #elIntersecting = false;
  #paused = false;
  #dragging = false;
  #itemsOffsetLeft = [];
  #originalItems = [];
  #clonedItems = [];
  #eventUnregisterHandlers = [];
  #startX = 0;

  /**
   * @constructor
   * @param {jQuery|HTMLElement} el - jQuery object or HTMLElement instance of the marquee wrapper
   * @param {Marquee#MarqueeOptions} [options = {}] - Options for the marquee
   */

  constructor(el, options = {}) {
    this.el = el;
    this.options = { ..._defaults, ...options };

    if (this.options.allowDrag) {
      this.el.classList.add("marquee--draggable");
    }

    this.#originalItems = [...this.el.children];
    this.#buildSlides();
    this.#observe();
    this._render();
    this.#addEvents();
    this.#elIntersectionObserver = new IntersectionObserver((entries) => {
      this.#elIntersecting = entries[0].isIntersecting;
    });
    this.#elIntersectionObserver.observe(this.el);
  }

  #observe() {
    this.#observer = new IntersectionObserver(this._onIntersection.bind(this), {
      root: this.el,
    });
    [...this.el.children].map((el) => {
      this.#observer.observe(el);
    });
  }

  _render() {
    cancelAnimationFrame(this.#rafId);
    this.#rafId = requestAnimationFrame(this._render.bind(this));
    if (!this.#elIntersecting) return;

    if (!this.#paused && !this.#dragging) {
      const addition = this.options.direction === "left" ? -this.options.speed : this.options.speed;
      this.#itemsOffsetLeft = this.#itemsOffsetLeft.map((left) => left + addition);
    }

    if (this.#dragging || !this.#paused) {
      [...this.el.children].forEach((el, i) => {
        el.style.left = this.#itemsOffsetLeft[i] + "px";
      });
    }
  }

  #maybeCloneItems() {
    this.#clonedItems = [...this.el.children];

    const totalItemsWidth = [...this.el.children].reduce((acc, el) => {
      const style = el.currentStyle || window.getComputedStyle(el);
      acc += el.offsetWidth + parseFloat(style.marginLeft) + parseFloat(style.marginRight);
      return acc;
    }, 0);

    if (this.el.offsetWidth < totalItemsWidth || !totalItemsWidth) return;

    this.#originalItems.forEach((el) => {
      this.el.appendChild(el.cloneNode(true));
    });

    this.#clonedItems = [...this.el.children];
    this.#maybeCloneItems();
  }

  #buildSlides() {
    const wrapper = document.createElement("div");
    wrapper.classList.add("marquee__slide");

    const totalWidth = [...this.#clonedItems].reduce((acc, el) => {
      const style = el.currentStyle || window.getComputedStyle(el);
      acc += el.offsetWidth + parseFloat(style.marginLeft) + parseFloat(style.marginRight);
      return acc;
    }, 0);
    wrapper.style.width = totalWidth + "px";

    [...this.#clonedItems].forEach((el) => {
      wrapper.appendChild(el.cloneNode(true));
    });

    this.el.replaceChildren(
      wrapper.cloneNode(true),
      wrapper.cloneNode(true),
      wrapper.cloneNode(true),
    );

    this.#itemsOffsetLeft = new Array(3).fill(-totalWidth);
  }

  _onIntersection(entries) {
    if (!this.#elIntersecting) return;
    entries.forEach((entry) => {
      const { isIntersecting, boundingClientRect, rootBounds } = entry;

      if (!isIntersecting) {
        // Leaving to the left
        if (boundingClientRect.left < rootBounds.left) {
          const mostLeft = [...this.el.children].sort((a, b) => a.offsetLeft - b.offsetLeft)[0];
          if (mostLeft !== entry.target) {
            const index = [...this.el.children].indexOf(mostLeft);
            this.#itemsOffsetLeft[index] =
              this.#itemsOffsetLeft[index] + boundingClientRect.width * 3;
          }
        }
      } else {
        // Entering from the left
        if (
          boundingClientRect.left + boundingClientRect.width <
          rootBounds.left + rootBounds.width * 0.5
        ) {
          const mostRight = [...this.el.children].sort((a, b) => b.offsetLeft - a.offsetLeft)[0];
          if (mostRight !== entry.target) {
            const index = [...this.el.children].indexOf(mostRight);
            this.#itemsOffsetLeft[index] =
              this.#itemsOffsetLeft[index] - boundingClientRect.width * 3;
          }
        }
      }
    });
  }

  #onResize() {
    this.#observer.disconnect();
    this.el.replaceChildren(...this.#originalItems);
    this.#maybeCloneItems();
    this.#buildSlides();
    this.#observe();
  }

  #onMouseEnter() {
    if (this.options.pauseOnHover) {
      this.#paused = true;
    }
  }

  #onMouseLeave() {
    if (this.options.pauseOnHover) {
      this.#paused = false;
    }
    this.#onMouseUp();
  }

  #onMouseDown(e) {
    if (this.options.allowDrag) {
      this.el.classList.add("marquee--dragging");
      this.#dragging = true;
      this.#startX = e.clientX || e.touches[0].clientX;
      this.on("mousemove", this.el, this.#onMouseMove);
      this.on("touchmove", this.el, this.#onMouseMove, { passive: true });
      this.on("mouseup", this.el, this.#onMouseUp);
      this.on("touchend", this.el, this.#onMouseUp, { passive: true });
      this.on("mouseleave", this.el, this.#onMouseLeave);
    }
  }

  #onMouseMove(e) {
    if (this.#dragging && this.options.allowDrag) {
      const clientX = e.clientX || e.touches[0].clientX;
      const distance = clientX - this.#startX;
      this.#itemsOffsetLeft = this.#itemsOffsetLeft.map((left) => left + distance);
      this.#startX = clientX;
    }
  }

  #onMouseUp() {
    if (this.#dragging && this.options.allowDrag) {
      this.#dragging = false;
      this.#eventUnregisterHandlers.find(({ type }) => type === "mousemove")?.handler();
      this.#eventUnregisterHandlers.find(({ type }) => type === "touchmove")?.handler();
      this.#eventUnregisterHandlers.find(({ type }) => type === "mouseup")?.handler();
      this.#eventUnregisterHandlers.find(({ type }) => type === "touchend")?.handler();
      this.el.classList.remove("marquee--dragging");
    }
  }

  #addEvents() {
    this.#resizeObserver = new ResizeObserver(this.#onResize.bind(this));
    this.#resizeObserver.observe(this.el);
    this.on("mouseenter", this.el, this.#onMouseEnter);
    this.on("mouseleave", this.el, this.#onMouseLeave);
    this.on("mousedown", this.el, this.#onMouseDown);
    this.on("touchstart", this.el, this.#onMouseDown, { passive: true });
  }

  #removeEvents() {
    this.#resizeObserver.disconnect();
    this.#eventUnregisterHandlers.forEach(({ handler }) => handler());
  }

  on(type, element, handler, options = {}) {
    const _handler = handler.bind(this);
    element.addEventListener(type, _handler, options);
    this.#eventUnregisterHandlers.push({
      type,
      handler: () => element.removeEventListener(type, _handler, options),
    });
  }

  /**
   * Destroy the marquee
   * @example
   * const marquee = new Marquee(document.querySelector(".marquee"), {});
   * marquee.destroy();
   */
  destroy() {
    cancelAnimationFrame(this.#rafId);
    this.#removeEvents();
    this.#observer.disconnect();
    this.#elIntersectionObserver.disconnect();
    this.#itemsOffsetLeft = [];
    this.el.replaceChildren(...this.#originalItems);
    this.el.classList.remove("marquee--draggable", "marquee--dragging");
  }
}
export { Marquee as default };
