import $ from "jquery";
import "js/components/propertyCard";
import { Swiper } from "swiper";
import { Autoplay } from "swiper/modules";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/effect-fade";
import Marquee from "js/src/Marquee";

$(".marquee").each(function () {
  const marquee = new Marquee(this, {
    pauseOnHover: true,
    allowDrag: true,
  });
});

$(".js-property-slider").each(function () {
  const enableAutoplay = $(this).hasClass("enable-autoplay");
  const enableLoop = $(this).hasClass("enable-loop");

  const swiper = new Swiper(this.querySelector(".swiper"), {
    modules: [Autoplay],
    slidesPerView: "auto",
    spaceBetween: 20,
    autoplay: enableAutoplay,
    loop: enableLoop,
  });
});

$(".js-destination-slider").each(function () {
  const enableAutoplay = $(this).hasClass("enable-autoplay");
  const enableLoop = $(this).hasClass("enable-loop");

  const swiper = new Swiper(this.querySelector(".swiper"), {
    modules: [Autoplay],
    slidesPerView: "auto",
    spaceBetween: 20,
    autoplay: enableAutoplay,
    loop: enableLoop,
  });
});

$(".js-setters-slider").each(function () {
  const enableAutoplay = $(this).hasClass("enable-autoplay");
  const enableLoop = $(this).hasClass("enable-loop");

  const swiper = new Swiper(this.querySelector(".swiper"), {
    modules: [Autoplay],
    slidesPerView: "auto",
    spaceBetween: "60",
    autoplay: enableAutoplay,
    loop: enableLoop,
  });
});
