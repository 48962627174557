import $ from "jquery";
import app from "js/src/App";

const updateFavorite = (id, favorite) => {
  return new Promise((resolve, reject) => {
    const onSuccess = (data) => {
      $(`.property .wishlist-icon[data-id="${id}"]`).attr(
        "data-is-favorite",
        data.status === "is_favorite" ? 1 : 0,
      );
      resolve();
    };

    $.ajax({
      url: `/wp-json/custom/v1/property/favorite/${id}`,
      method: "POST",
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify({
        status: favorite ? "is_favorite" : "is_not_favorite",
      }),
      success: ({ data, success }) => {
        if (success) {
          onSuccess(data);
          return;
        }
        reject();
      },
      error: ({ responseJSON: { data } }) => {
        if (data?.slug === "already_existing_favorite") {
          onSuccess({ status: "is_favorite" });
          return;
        }
        reject();
      },
    });
  });
};

$(".property .wishlist-icon").click(async function (e) {
  e.preventDefault();
  if (!sso_user_helper.logged_in) {
    app.subscribePostLogin(
      `property-${$(this).data("id")}`,
      async () => {
        app.unsubscribePostLogin(`property-${$(this).data("id")}`);
        await updateFavorite($(this).data("id"), 1);
      },
      "first",
    );

    app.loginPopup.addEventListener("cancel", () => {
      app.unsubscribePostLogin(`property-${$(this).data("id")}`);
    });

    app.loginPopup.open();
    return;
  }

  await updateFavorite($(this).data("id"), !parseInt($(this).attr("data-is-favorite")));
});
